<template>
    <div class="page-table haberTanim  mainDiv" id="affix-container">
        <div class="animated fadeInUp card-base baslikBg">
            {{$t("src.views.apps.galeri.update.title")}}
        </div>
        <!-- loading true ise v-loading çalışıyor ve if kosulunda loading için olan mesaj yazdırılıyor. Ama loading false ise detailLoading true olmus oluyor ve if kosulunda false 'a düşüp diğer mesaj yazdırılıyor. İkiside false ise zaten v-loading calısmıyor. -->
        <el-row :gutter="10" v-loading="loading || detailLoading" :element-loading-text='loading ? $t("src.views.apps.genel.updateLoading") : $t("src.views.apps.galeri.update.detailLoading")'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
            <el-form status-icon :model="galeriForm" :rules="rulesSlider" ref="galeriForm" class="demo-ruleForm">

                <el-col :lg="18" :md="18" :sm="24" :xs="24">
                    <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft">
                        <el-row>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item :label='$t("src.views.apps.galeri.update.header")' prop="anaBaslik">:
                                    <el-input size="small" v-model="galeriForm.anaBaslik" :placeholder='$t("src.views.apps.galeri.update.headerPlace")'></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item :label='$t("src.views.apps.galeri.update.image") + " (800x600)"' prop="resim">:
                                    <el-upload
                                        class="resimler"
                                        action=""
                                        list-type="picture-card"
                                        :file-list="imageListMain"
                                        :auto-upload="false"
                                        accept=".jpg,.jpeg,.png"
                                        :on-change="imageUpload"
                                        :on-remove="imageRemove">
                                        <i slot="default" class="el-icon-plus"></i>
                                    </el-upload>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>

                <el-col :lg="6" :md="6" :sm="24" :xs="24">
                    <el-collapse class="yanForm animated fadeInUp" v-model="activeCollapse">
                        <el-collapse-item name="1" :title='$t("src.views.apps.genel.durum")'>
                            <el-row>
                                <div>
                                    <el-button size="mini" class="onayBtn" v-on:click="galeriGuncelle('galeriForm')" type="primary">
                                        {{$t('src.views.apps.genel.guncelle')}}
                                    </el-button>
                                </div>
                            </el-row>
                        </el-collapse-item>
                    </el-collapse>
                </el-col>

            </el-form>
        </el-row>
    </div>
</template>

<script>
    import galeriService from '../../../WSProvider/GaleriService';
    import Resim from '@/components/Resim.vue'
    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from '@/components/event-bus'

    import JQuery from 'jquery';
    let $ = JQuery;

    export default {
        name: "galeriguncelle",
        components: {
            Resim
        },
        data() {
            return {
                loading: false,
                detailLoading: false,

                activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                imageListMain: [],
                galeriForm: {
                    galeriID: "",
                    anaBaslik: "",
                    altBaslik: "",
                    resim: [],
                    durum: "1",
                },
                rulesSlider: {
                    anaBaslik: [{
                        required: true,
                        message: "Please Enter Title",
                        trigger: 'blur'
                    }],
                    resim: [{
                        required: true,
                        message: "Please Select Image(s)",
                        trigger: ['blur', 'trigger']
                    }],
                },
            }
        },
        computed: {
            galeriUpdateData(){
                return this.$store.getters.getGaleriUpdateData;
            }
        },
        watch: {
            galeriUpdateData(val){
                this.getGaleriData(val);
            }
        },
        mounted() {
            this.getGaleriData(this.$store.getters.getGaleriUpdateData);
        },
        methods: {
            getGaleriData(val){
                try{
                    var self = this;
                    this.detailLoading = true;
                    this.imageListMain = [];
                    this.galeriForm.resim = [];
                    galeriService.galeriDetay(val.galeriID).then(response => {
                        if(response.status == 200){
                            if (response.data.resim) {
                                if(response.data.resim !== ''){
                                    self.imageListMain.push({
                                        name: response.data.resim,
                                        url: galeriService.imagePath + response.data.resim
                                    });
                                    $('.el-upload--picture-card').hide();
                                }
                                this.galeriForm.resim.push(response.data.resim)
                            }
                            this.galeriForm.anaBaslik = response.data.baslik;
                            this.galeriForm.durum = response.data.durum;
                            this.galeriForm.galeriID = response.data.galeriID;
                        }
                        this.detailLoading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                            this.galeriForm = {};
                        }
                        this.detailLoading = false;
                    });
                }catch(e){
                    this.detailLoading = false;
                }
            },

            galeriGuncelle(formName) {
                if(this.validateForm(formName)){
                    this.openConfirmDialog("Resmi güncellemek istediğinizden emin misiniz?").then(() => {
                        try{
                            this.loading = true;
                            galeriService.galeriGuncelle(this.galeriForm.galeriID, this.galeriForm.anaBaslik, this.galeriForm.resim[0], this.galeriForm.durum).then(response => {
                            if(response.status == 200){
                                    localStorage.setItem("userDataDemirag", response.token)
                                    this.resetForm(formName);
                                    EventBus.$emit("galeriList", true)
                                    this.sayfaKapat('galeriguncelle')
                                }
                                this.loading = false;
                                notification.Status('success', this, response.msg);
                            }).catch(err => {
                                if(err.responseJSON){
                                    let error = err.responseJSON
                                    if(error.status == 401){
                                        notification.Status(150, this);
                                    }
                                    else notification.Status("errorMsg", this, error.errMsg)
                                }else{
                                    //notification.Status(503, this)
                                }
                                this.loading = false;
                            });
                        }catch(e){
                            this.loading = false
                        }
                    })
                }
            },

            imageRemove(file) {
                this.imageListMain.splice(this.imageListMain.indexOf(file), 1);
                $('.el-upload--picture-card').show();
                this.galeriForm.resim = [];
            },

            imageUpload(file, fileList) {
                this.imageListMain.length === 0 ? $('.el-upload--picture-card').show(): $('.el-upload--picture-card').hide();
                this.imageListMain = []
                this.galeriForm.resim = []
                if ((file.raw["type"] === 'image/jpg' || file.raw["type"] === 'image/jpeg' || file.raw["type"] === 'image/png') && this.imageListMain.length < 2) {
                    var item = {
                        name: file.name,
                        url: file.url,
                        id: file.raw.lastModified,
                        size: file.raw.size
                    };
                    this.imageListMain.push(item)

                    file.raw["tmp_name"] = file.url; // backend file_url'i tmp_name değişkeninden alıyor
                    
                    this.galeriForm.resim.push(file.raw)
                    $('.el-upload--picture-card').hide();
                    fileList.pop();
                }
            },
            
            resetForm(formName) {
                this.$refs[formName].resetFields();
                $('.el-upload--picture-card').show();
                this.imageListMain = [];
            },
        }
    }

</script>

<style lang="scss" scoped>
    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 200px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
    }

    .cop {
        color: #ec205f;
        position: relative;
        float: right;
        right: 15px;
        bottom: 10px;
        font-size: 20px;
    }

    .yanForm .card {
        margin-top: 10px;
    }
</style>